import { inviteCodeAtom, isPinExistsAtom } from '../state'
import { useAtom } from 'jotai/index'
import { IWeb3ChainType, Web3Type } from '../proviers/web3Provider/type'
import { useCallback, useMemo } from 'react'
import { mnemonicToPrivateKey, recoverSeedWords } from 'utils/wallet'
import { useQuery } from '@tanstack/react-query'

import { mockTronChainId } from 'config/tron'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import walletStore from '@/stores/walletStore'
import { getChainByChainId } from '@/stores/walletStore/utils'
import {
  displayName,
  getBtcWallet,
  userAddressList
} from '@/stores/userStore/utils'
import chains from '@/proviers/web3Provider/chains'
import useUserStore from '@/stores/userStore/hooks/useUserStore'

export type UserType = {
  // nickname: string
  username: string
  token: string
  avatar: string
  id: number
  displayName: string
  bitcoinP2pkhAddress: string
  bitcoinP2trAddress: string
  bitcoinP2shAddress: string
  bitcoinP2wpkhAddress: string
  ethereumAddress: string
  solanaAddress: string
  tronAddress: string
  tonAddress: string
  tonPublicKey?: string
  nickname?: string
  tgId?: string
  tgName?: string
  email?: string
}

export default function useLoginInfo() {
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const { user } = useUserStore()

  const walletAddress = useMemo(() => {
    if (walletStore.chainId == -1) return ''
    const walletType = getChainByChainId(walletStore.chainId)?.type
    const userAddress = userAddressList(user)
    if (walletType == 'EVM') {
      return userAddress?.ethereumAddress
    }
    if (walletType == 'SOL') {
      return userAddress?.solanaAddress
    }
    if (walletType == 'TON') {
      return userAddress?.tonAddress
    }
    if (walletType == 'TRON') {
      return userAddress?.tronAddress
    }
    if (walletType == 'BTC') {
      switch (walletStore.btcWalletType) {
        case 'bitcoinP2Pkh':
          return userAddress?.bitcoinP2pkhAddress
        case 'bitcoinP2Tr':
          return userAddress?.bitcoinP2trAddress
        case 'bitcoinP2Sh':
          return userAddress?.bitcoinP2shAddress
        case 'bitcoinP2Wpkh':
          return userAddress?.bitcoinP2wpkhAddress
        default:
          return userAddress?.bitcoinP2pkhAddress
      }
    }
    if (walletType == 'SUI') {
      return userAddress?.suiAddress
    }
    return userAddress?.ethereumAddress
  }, [user, walletStore.chainId, walletStore.btcWalletType])

  const evmAddress = user?.ethereumAddress
  const solAddress = user?.solanaAddress
  const tonAddress = user?.tonAddress
  const tronAddress = user?.tronAddress
  const suiAddress = user?.suiAddress
  const tonPublicKey = user?.tonPublicKey

  const btcAddress = {
    bitcoinP2PkhAddress: user?.bitcoinP2pkhAddress,
    bitcoinP2ShAddress: user?.bitcoinP2shAddress,
    bitcoinP2TrAddress: user?.bitcoinP2trAddress,
    bitcoinP2WpkhAddress: user?.bitcoinP2wpkhAddress
  }

  const btcWallet = () => {
    console.log({
      btcAddress: getBtcWallet(user, walletStore.btcWalletType)
    })

    return getBtcWallet(user, walletStore.btcWalletType)
  }

  const getAddressByToken = (token: AssetsToken) => {
    if (!token || !user) return ''
    if (token.symbol == Web3Type.BTC) {
      return getBtcWallet(user, walletStore.btcWalletType)
    }
    if (token.symbol == Web3Type.SOL) {
      return user?.solanaAddress
    }
    if (token.chainId == chains.solana.id) {
      return user?.solanaAddress
    }
    if (token.chainId == chains.ton.id) {
      return user?.tonAddress
    }
    if (token.chainId == chains.tron.id) {
      return user?.tronAddress
    }
    if (token.chainId == chains.sui.id) {
      return user?.suiAddress
    }
    return user?.ethereumAddress
  }

  const getRenderAddress = (chain: IWeb3ChainType | undefined) => {
    switch (chain?.type) {
      case Web3Type.EVM:
        return user?.ethereumAddress
      case Web3Type.SOL:
        return user?.solanaAddress
      case Web3Type.BTC:
        return btcWallet()
      case Web3Type.TON:
        return user?.tonAddress
      case Web3Type.TRON:
        return user?.tronAddress
      default:
        return user?.ethereumAddress
    }
  }

  const getBtcWalletTypeByAddress = (address: string) => {
    switch (address) {
      case btcAddress.bitcoinP2PkhAddress:
        return 'bitcoinP2Pkh'
      case btcAddress.bitcoinP2TrAddress:
        return 'bitcoinP2Tr'
      case btcAddress.bitcoinP2ShAddress:
        return 'bitcoinP2Sh'
      case btcAddress.bitcoinP2WpkhAddress:
        return 'bitcoinP2Wpkh'
      default:
        return 'bitcoinP2Pkh'
    }
  }

  return {
    btcType: walletStore.btcWalletType,
    btcWallet,
    user: { ...user, displayName: displayName(user) },
    setWalletType: walletStore.btcWalletTypeActions,
    walletType: walletStore.btcWalletType,
    walletAddress,
    evmAddress,
    solAddress,
    btcAddress,
    tronAddress,
    tonAddress,
    suiAddress,
    tonPublicKey,
    setInviteCode,
    inviteCode,
    getAddressByToken,

    getAddressAsChainId: (token: AssetsToken) => {
      if (!token || !user) return ''
      if (token.chainId == 0) {
        if (walletStore.btcWalletType == 'bitcoinP2Pkh') {
          return btcAddress.bitcoinP2PkhAddress
        } else if (walletStore.btcWalletType == 'bitcoinP2Tr') {
          return btcAddress?.bitcoinP2TrAddress
        } else if (walletStore.btcWalletType == 'bitcoinP2Sh') {
          return btcAddress?.bitcoinP2ShAddress
        } else if (walletStore.btcWalletType == 'bitcoinP2Wpkh') {
          return btcAddress?.bitcoinP2WpkhAddress
        }
        return user?.bitcoinP2trAddress
      }
      if (token.chainId == chains.solana.id) {
        return user?.solanaAddress
      }
      if (token.chainId == chains.ton.id) {
        return user?.tonAddress
      }
      if (token.chainId == chains.tron.id) {
        return user?.tronAddress
      }
      if (token.chainId === chains.sui.id) {
        return user?.suiAddress
      }
      return user?.ethereumAddress
    },
    getRenderAddress,
    userAddress: userAddressList(user),
    getBtcWalletTypeByAddress
  }
}
