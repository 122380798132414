import {
  receiveToRampDark,
  receiveToRampLight,
  visaSvg,
  applePay,
  mastercardSvg,
  applyPayDark,
  googlePaylight,
  googlePayDark
} from '@/assets'
import { TButton, TIcon, TIconButton } from '@/components/tmd'
import usePopup from '@/hooks/popup/usePopup'
import { Routers } from '@/router'
import rampStore from '@/stores/rampStore'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import useTheme from '@/stores/userStore/hooks/useTheme'
import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export enum ReceiveTabKeys {
  Crypto = 'crypto',
  AddCash = 'addCash',
  Star = 'star'
}

interface ReceiveTabsType {
  icon: React.ReactNode
  key: ReceiveTabKeys
  label: string
}

export default function ReceiveTabButtons({
  token
}: {
  token: AssetsToken | undefined
}) {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const lightTheme = theme === 'light'
  console.log('tokentoken', token)
  const receiveTabs: ReceiveTabsType[] = [
    // {
    //   icon: <TIcon name="tg_wallet_Cryptocurrency" />,
    //   key: ReceiveTabKeys.Crypto,
    //   label: 'Crypto'
    // }
  ]
  if (
    token?.isNative ||
    token?.symbol.toLowerCase() === 'usdt' ||
    token?.symbol.toLowerCase() === 'usdc'
  ) {
    receiveTabs.push({
      icon: <TIcon name="tg_wallet_Card" />,
      key: ReceiveTabKeys.AddCash,
      label: 'Add cash'
    })
  }

  const payTypeIconList = [
    {
      key: 'masterCard',
      icon: <img src={mastercardSvg} />
    },
    {
      key: 'visa',
      icon: <img src={visaSvg} />
    },
    {
      key: 'applePay',
      icon: <img src={lightTheme ? applePay : applyPayDark} alt="" />
    },
    {
      key: 'googlePay',
      icon: <img src={lightTheme ? googlePaylight : googlePayDark} alt="" />
    }
  ]

  const clickItem = (token: AssetsToken) => {
    if (!token) return
    rampStore.setSelectedToken(token)
    navigate(Routers.ramp.trade())
  }

  const { setOpen, component } = usePopup({
    content: (
      <div className="mt-[50px] flex flex-col items-center">
        <img
          className="mb-[8px] size-[200px]"
          src={lightTheme ? receiveToRampLight : receiveToRampDark}
          alt=""
        />
        <span
          className="mb-[18px] text-xl text-t1
"
        >
          Use fiat currency to buy crypto
        </span>
        <div className="mb-[100px] flex gap-[8px]">
          {payTypeIconList.map((item) => (
            <div
              className="box-border flex h-[32px] w-[68px] items-center justify-center bg-bg3"
              key={item.key}
            >
              {item.icon}
            </div>
          ))}
        </div>

        <TButton
          size="large"
          className="mb-[12px] w-full"
          onClick={() => clickItem(token)}
        >
          Continue
        </TButton>
        <TButton
          size="large"
          theme="ghost"
          className="w-full !bg-transparent !text-t1"
          onClick={() => setOpen(false)}
        >
          Cancel
        </TButton>
      </div>
    ),
    showCloseButton: false
  })

  return (
    <>
      {component}
      {receiveTabs.map((tab) => (
        <TIconButton
          key={tab.key}
          onClick={() => {
            if (tab.key === ReceiveTabKeys.AddCash) {
              if (!token) return
              setOpen(true)
            }
          }}
          className={classNames(
            'box-border w-[160px]  border-[1px] border-l1 bg-transparent py-[8px] text-t1',
            { 'border-t1': tab.key === ReceiveTabKeys.Crypto },
            { 'opacity-50': tab.key === ReceiveTabKeys.AddCash && !token }
          )}
        >
          {tab.icon}
          <span className="ml-[4px] text-xs">{tab.label}</span>
        </TIconButton>
      ))}
    </>
  )
}
