// type="module"
import React, { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import VConsole from 'vconsole'
import { PageLoading } from './components/PageLoading'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import App from './App'
import { ConfigProvider } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import 'tailwindcss/tailwind.css'
import * as Sentry from '@sentry/react'
import './i18n/i18n'
import './utils/firebase'

if (import.meta.env.VITE_NODE_ENV !== 'location') {
  Sentry.init({
    dsn: 'https://35470d2ed8315d3a7fd6afdd64f1e64f@o4508120700289024.ingest.us.sentry.io/4508120706056192',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

// console.log('env', import.meta.env)
if (import.meta.env.VITE_CONSOLE == '1') {
  import('vconsole').then(({ default: VConsole }: any = {}) => {
    console.log('init vconsole')
    new VConsole()
    console.log('init vconsole finish')
  })
}
(async () => {
  const response = await fetch('/ramp/achramp-risk-sdk.min.js')
  const text = await response.text()
  const blob = new Blob([text], { type: 'application/javascript' })
  const script = document.createElement('script')
  script.src = URL.createObjectURL(blob)
  document.body.appendChild(script)
  await new Promise((resolve, reject) => {
    script.onload = resolve
    script.onerror = reject
  })
})()

// const App = lazy(() => import('App'))
root.render(
  <Suspense fallback={<PageLoading />}>
    <ConfigProvider locale={enUS}>
      <App />
    </ConfigProvider>
  </Suspense>
)
