import { makeAutoObservable } from 'mobx'

class CommonStore {
  balanceFetchSwitch: boolean = false
  viewHeight: number = 0
  staticHeight: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  updatebalanceSwitch(flag: boolean) {
    this.balanceFetchSwitch = flag
  }
  updateViewHeightChange(num: number) {
    this.viewHeight = num
  }
  updateStaticViewHeight(num: number) {
    this.staticHeight = num
    this.viewHeight = num
  }
}

const commonStore = new CommonStore()
export default commonStore
