import { TIcon } from '@/components/tmd'

interface IProps {
  content: string
}
const Tip = ({ content }: IProps) => {
  return (
    <div className="mb-4 flex items-start gap-2 rounded-[8px] border border-l1 p-3 text-xs text-t3">
      <TIcon
        name="tg_wallet_present-facetiousness"
        className="text-orange"
        fontSize="20"
      />
      <div className="flex flex-col gap-2">
        <span className="text-sm text-t1">Attention</span>
        <span>{content}</span>
      </div>
    </div>
  )
}

export default Tip
