import { CustomListInfo, WhiteListInfo } from '@/api/type'
import { APIToken } from './type/APIToken'
import { STORAGE_KEY } from '@/utils/cacheManage'
import { AssetsToken } from './type/AssetsToken'
import { IWeb3ChainType } from '@/proviers/web3Provider/type'
import { CHAIN_MAPS_ID } from '@/pages/market/chains'

export function mergeTokensData({
  whiteTokens,
  customTokens
}: {
  whiteTokens: WhiteListInfo[]
  customTokens: CustomListInfo[]
}): APIToken[] {
  // Filter out the chain_id and token combinations in customQueryData that already exist in queryData
  const filteredCustomQueryData: APIToken[] =
    customTokens
      ?.filter((n: CustomListInfo) => {
        return !whiteTokens.find(
          (m: WhiteListInfo) =>
            m.contract?.toLocaleUpperCase() === n.token.toLocaleUpperCase() &&
            m.chain_id === n.chain_id
        )
      })
      .map(
        (i) =>
          ({
            isNative: !i.token,
            isToken: !!i.token,
            chainId: i.chain_id,
            decimals: i.decimals,
            symbol: i.symbol,
            name: i.name,
            address: i.token,
            balance: '0', //not trust
            price: i.price,
            image: i.image,
            source: 'custom',
            whiteToken: undefined,
            customToken: i
          }) as APIToken
      ) || []

  // Formatting queryData data
  const formattedQueryData =
    whiteTokens?.map((i) => {
      const symbol = i.symbol.includes('ETH') ? 'ETH' : i.symbol
      return {
        isNative: i.is_native,
        isToken: !i.is_native,
        chainId: i.chain_id,
        decimals: i.decimals,
        symbol,
        name: i.name,
        address: i.contract,
        balance: '0', //not trust
        price: i.price,
        image: i.image,
        source: 'all',
        whiteToken: i,
        customToken: undefined
      } as APIToken
    }) || []

  return [...formattedQueryData, ...filteredCustomQueryData]
}

export function shallowEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  return arr1.every((item, index) => item === arr2[index])
}

export function shallowAssetsTokenEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  if (arr1.length === 0) return false
  let flag = 0
  arr1.forEach((asset1, idx) => {
    const assets2 = arr2[idx]
    if (!assetsTokenEqual(asset1, assets2)) {
      flag++
    }
  })
  return flag === 0
}
export function shallowCustomListInfoEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  if (arr1.length === 0) return false
  let flag = 0
  arr1.forEach((asset1, idx) => {
    const assets2 = arr2[idx]
    if (!customListInfoEqual(asset1, assets2)) {
      flag++
    }
  })
  return flag === 0
}
export function shallowWhiteListInfoEqual(arr1: any[], arr2: any[]) {
  if (arr1 === arr2) return true
  if (arr1.length !== arr2.length) return false
  if (arr1.length === 0) return false
  let flag = 0
  arr1.forEach((asset1, idx) => {
    const assets2 = arr2[idx]
    if (!whiteListInfoEqual(asset1, assets2)) {
      flag++
    }
  })
  return flag === 0
}

const assetsTokenEqual = (a: AssetsToken, b: AssetsToken) => {
  return (
    a.isNative === b.isNative &&
    a.isToken === b.isToken &&
    a.chainId === b.chainId &&
    a.decimals === b.decimals &&
    a.symbol === b.symbol &&
    a.name === b.name &&
    a.address === b.address &&
    a.balance === b.balance &&
    a.price === b.price &&
    a.image === b.image &&
    a.formatted === b.formatted &&
    a.customToken?.price_change_h24 === b.customToken?.price_change_h24
  )
}

const customListInfoEqual = (a: CustomListInfo, b: CustomListInfo) => {
  return (
    a.ID === b.ID &&
    a.chain_id === b.chain_id &&
    a.decimals === b.decimals &&
    a.image === b.image &&
    a.market_cap === b.market_cap &&
    a.name === b.name &&
    a.price === b.price &&
    a.price_change_h24 === b.price_change_h24 &&
    a.symbol === b.symbol &&
    a.token === b.token &&
    a.uid === b.uid
  )
}

const whiteListInfoEqual = (a: WhiteListInfo, b: WhiteListInfo) => {
  return (
    a.chain_id === b.chain_id &&
    a.contract === b.contract &&
    a.decimals === b.decimals &&
    a.image === b.image &&
    a.is_native === b.is_native &&
    a.mercuryo_support === b.mercuryo_support &&
    a.name === b.name &&
    a.price === b.price &&
    a.ramp_support === b.ramp_support &&
    a.symbol === b.symbol
  )
}

export const getWalletTokensKey = () => {
  let walletId = null
  const userStr = localStorage.getItem(STORAGE_KEY.user)
  if (userStr) {
    const userInfo = JSON.parse(userStr)
    walletId = userInfo['defaultWalletId'] ?? -1
    return `${STORAGE_KEY.TOKENS_LIST}_${walletId}`
  }
  return null
}

export const getNativeToken = (
  tokenList: AssetsToken[],
  iChain?: IWeb3ChainType | undefined
) => {
  if (!iChain) {
    return tokenList.filter((token) => token.isNative)[0]
  }
  return tokenList.find(
    (token) => token.isNative && token.chainId === iChain.id
  )
}

export const getBalance = (
  tokenList: AssetsToken[],
  chainId: number,
  address: string
) =>
  tokenList.find(
    (token) =>
      token.address.toLocaleUpperCase() === address.toLocaleUpperCase() &&
      token.chainId === chainId
  )

export const getReceiveToken = (
  source: AssetsToken[],
  chainId: number | undefined,
  symbol: string | undefined,
  address: string | undefined
) => {
  return source.find((token) => {
    const polFlag = symbol === 'MATIC' && token.symbol === 'POL'
    return (
      token.chainId === chainId &&
      (token.symbol.toLowerCase() === symbol?.toLowerCase() || polFlag) &&
      token.address.toLowerCase() === address?.toLowerCase()
    )
  })
}

export const addChainIdToApiReq = (source: any[]) => {
  if (!source || source.length === 0) return source
  return [...source].map((tread) => {
    tread.chainId = (CHAIN_MAPS_ID as any)[tread.chain] ?? -1
    return tread
  })
}
