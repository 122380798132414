import chains from '@/proviers/web3Provider/chains'

// {API.chain: chain.name}
export const CHAIN_MAPS = {
  ARBITRUM: 'Arbitrum One',
  AVAX: 'Avalanche',
  B3: 'B3',
  BASE: 'Base',
  B2: 'B2',
  BITCOIN: 'Bitcoin',
  BITLAYER: 'BITLAYER',
  BLAST: 'Blast',
  BSC: 'BNB Smart Chain',
  BOB: 'BOB',
  BOUNCEBIT: 'BOUNCEBIT',
  ETH: 'Ethereum',
  CRONOS: 'CRONOS',
  LINEA: 'Linea Mainnet',
  FANTOM: 'FANTOM',
  GNOSIS: 'GNOSIS',
  LORENZO: 'LORENZO',
  OPTIMISM: 'OP Mainnet',
  MANTA: 'MANTA',
  POLYGON_POS: 'Polygon',
  MANTLE: 'MANTLE',
  MODE: 'MODE',
  MEVM: 'MEVM',
  SOLANA: 'Solana',
  SCROLL: 'Scroll',
  TAIKO: 'TAIKO',
  ZKSYNC: 'ZKSYNC',
  TON: 'TON',
  TRON: 'TRON',
  ZKLINK_NOVA: 'ZKLINK_NOVA'
} as Record<string, string>

export const CHAIN_MAPS_ID = {
  ARBITRUM: chains.arbitrum.id,
  AVAX: chains.avalanche.id,
  B3: chains.b3.id,
  BASE: chains.base.id,
  BITCOIN: chains.btc.id,
  BLAST: chains.blast.id,
  BSC: chains.bsc.id,
  ETH: chains.ethereum.id,
  LINEA: chains.linea.id,
  OPTIMISM: chains.optimism.id,
  POLYGON_POS: chains.polygon.id,
  MANTLE: chains.mantle.id,
  SOLANA: chains.solana.id,
  TON: chains.ton.id,
  TRON: chains.tron.id,
  SCROLL: chains.scroll.id,
  SUI: chains.sui.id,
  DuckChainTest: chains.duckChainTest.id,
  DuckChain: chains.duckChain.id,
  AILAYER: chains.ailayer.id,
  BITLAYER: chains.bitlayer.id,
  MERLIN: chains.merlin.id
}
