export interface ToSendParamsType {
  chainId: string
  toAddress: string
  address: string
  btcAdrType: string
  amount: string
}

export const Routers = {
  index: '/',
  login: '/login',
  swap: '/swap',
  invite: '/invite',
  gift: {
    create: (step?: number) => `/gift?step=${step || 0}`,
    manage: '/gift/manage',
    claim: (shareID: string) => `/gift/claim/${shareID}`,
    share: (shareID: string) => `/gift/share/${shareID}`,
    shareQrCode: (shareID: string) => `/gift/share-qrcode/${shareID}`
  },
  market: {
    root: '/market',
    tokenDetail: (chain: string, token: string) =>
      `/market/token/${chain}/${token}`
  },
  task: '/task',
  token: {
    send: () => `/send/select-token`,
    sendInputAmount: (params: Omit<ToSendParamsType, 'amount'>) => {
      const searchUrl = new URLSearchParams(params).toString()
      return `/send/input-amount?${searchUrl}`
    },
    sendConfirm: (params: ToSendParamsType) => {
      const searchUrl = new URLSearchParams(params).toString()
      return `/send/confirm-send?${searchUrl}`
    },
    receive: (chain: number, token: string) =>
      `/token/${chain}/${token}/receive`
  },
  ramp: {
    trade: (token?: string) => `/ramp/trade`,
    currency: () => `/ramp/currency`,
    cardDetails: `/ramp/card-details`,
    confirmPayment: (lastFourDigits: string, cardType: string) =>
      `/ramp/confirm-payment/${lastFourDigits}/${cardType}`,
    orderStatus: '/ramp/order-status',
    verificationCode: '/ramp/verification-code',
    choosePaymentMethod: '/ramp/choose-payment-method',
    paymentStatus: (merchantOrderNo: string) =>
      `/ramp/payment-status/${merchantOrderNo}`,
    history: '/ramp/history',
    iframePage: (webUrl: string) => `/ramp/iframe-page/${webUrl}`
  }
}
