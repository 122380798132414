import { http, createConfig } from '@wagmi/core'

import arbitrum from './wagmiConfig/arbitrum'
import arbitrumSepolia from './wagmiConfig/arbitrumSepolia'
import avalanche from './wagmiConfig/avalanche'
import base from './wagmiConfig/base'
import baseSepolia from './wagmiConfig/baseSepolia'
import blast from './wagmiConfig/blast'
import blastSepolia from './wagmiConfig/blastSepolia'
import bsc from './wagmiConfig/bsc'
import bscTestnet from './wagmiConfig/bscTestnet'
import ethereum from './wagmiConfig/ethereum'
import linea from './wagmiConfig/linea'
import lineaSepolia from './wagmiConfig/lineaSepolia'
import mantle from './wagmiConfig/mantle'
import mantleSepoliaTestnet from './wagmiConfig/mantleSepoliaTestnet'
import optimism from './wagmiConfig/optimism'
import optimismSepolia from './wagmiConfig/optimismSepolia'
import polygon from './wagmiConfig/polygon'
import sepolia from './wagmiConfig/sepolia'
import solana from './wagmiConfig/solana'
import allChain from './wagmiConfig/allChain'
import btc from './wagmiConfig/btc'
import ton from './wagmiConfig/ton'
import tron from './wagmiConfig/tron'
import scroll from './wagmiConfig/scroll'
import b3 from './wagmiConfig/b3'
import bitlayer from './wagmiConfig/bitlayer'
import merlin from './wagmiConfig/merlin'
import duckChainTest from './wagmiConfig/duckChainTest'
import duckChain from './wagmiConfig/duckChain'
import sui from './wagmiConfig/sui'
import ailayer from './wagmiConfig/ailayer'
import {} from 'viem/chains'

export const prodEvmChains = [
  ethereum,
  bsc,
  arbitrum,
  optimism,
  linea,
  polygon,
  base,
  blast,
  avalanche,
  scroll,
  b3,
  duckChain,
  ailayer,
  bitlayer,
  merlin,
  duckChainTest
]

export const renderChains = [btc, solana, ton, tron, sui, ...prodEvmChains]

export const allChains = [allChain, ...renderChains]

export const marketSelectChains = [
  allChain,
  ...renderChains.filter(
    (chain) => chain.id !== duckChainTest.id && chain.id !== duckChain.id
  )
]

export const evmChainsConfig = () => {
  return createConfig({
    chains: prodEvmChains.map((item) => item.chain) as any,
    transports: Object.fromEntries(
      prodEvmChains.map((chain) => chain?.id).map((key) => [key, http()])
    )
  })
}

export const evmChainConfig = (chainId: number) => {
  const findChains = prodEvmChains.filter((item) => item.id === chainId)
  if (!findChains.length) return undefined
  return createConfig({
    chains: findChains.map((item) => item.chain) as any,
    transports: {
      [chainId]: http()
    }
  })
}

const chains = {
  allChain,
  solana,
  arbitrum,
  arbitrumSepolia,
  avalanche,
  base,
  baseSepolia,
  blast,
  blastSepolia,
  bsc,
  bscTestnet,
  ethereum,
  linea,
  lineaSepolia,
  mantle,
  mantleSepoliaTestnet,
  optimism,
  optimismSepolia,
  polygon,
  btc,
  scroll,
  b3,
  tron,
  ton,
  duckChain,
  duckChainTest,
  ailayer,
  bitlayer,
  merlin,
  sui
}

export const marketChain = {
  [chains.btc.id]: {
    chain: 'BITCOIN',
    token: 'bitcoin'
  },
  [chains.ethereum.id]: {
    chain: 'ETH',
    token: 'ethereum'
  },
  [chains.solana.id]: {
    chain: 'SOLANA',
    token: 'solana'
  },
  [chains.ton.id]: {
    chain: 'TON',
    token: 'ton'
  },
  [chains.tron.id]: {
    chain: 'TRON',
    token: 'tron'
  },
  [chains.bsc.id]: {
    chain: 'BSC',
    token: 'bnb'
  },
  [chains.arbitrum.id]: {
    chain: 'ARBITRUM',
    token: 'arb_eth'
  },
  [chains.base.id]: {
    chain: 'BASE',
    token: 'base_eth'
  },
  [chains.blast.id]: {
    chain: 'BLAST',
    token: 'blast'
  },
  [chains.avalanche.id]: {
    chain: 'AVAX',
    token: 'avax'
  },
  [chains.polygon.id]: {
    chain: 'POLYGON_POS',
    token: 'matic'
  },
  [chains.scroll.id]: {
    chain: 'SCROLL',
    token: 'scroll_eth'
  },
  [chains.optimism.id]: {
    chain: 'OPTIMISM',
    token: 'op_eth'
  },
  [chains.linea.id]: {
    chain: 'LINEA',
    token: 'linea_eth'
  },
  [chains.b3.id]: {
    chain: 'B3',
    token: 'ethereum'
  },
  [chains.duckChainTest.id]: {
    chain: 'DuckChainTest',
    token: 'ton'
  },
  [chains.duckChain.id]: {
    chain: 'DuckChain',
    token: 'ton'
  },
  [chains.ailayer.id]: {
    chain: 'AILayer',
    token: 'btc'
  },
  [chains.bitlayer.id]: {
    chain: 'BitLayer',
    token: 'btc'
  },
  [chains.merlin.id]: {
    chain: 'Merlin',
    token: 'btc'
  },
  [chains.sui.id]: {
    chain: 'SUI',
    token: 'sui'
  }
  // [chains.sepolia.id]: {
  //   chain: 'SEPOLIA',
  //   token: 'ethereum'
  // }
}

export const onRampChain = {
  [chains.btc.id]: {
    chain: 'BTC',
    token: 'bitcoin'
  },
  [chains.ethereum.id]: {
    chain: 'ETH',
    token: 'ethereum'
  },
  [chains.solana.id]: {
    chain: 'SOL',
    token: 'solana'
  },
  [chains.ton.id]: {
    chain: 'TON',
    token: 'ton'
  },
  [chains.tron.id]: {
    chain: 'TRX',
    token: 'tron'
  },
  [chains.bsc.id]: {
    chain: 'BSC',
    token: 'bnb'
  },
  [chains.arbitrum.id]: {
    chain: 'ARBITRUM',
    token: 'arb_eth'
  },
  // [chains.base.id]: {
  //   chain: 'BASE',
  //   token: 'base_eth'
  // },
  // [chains.blast.id]: {
  //   chain: 'BLAST',
  //   token: 'blast'
  // },
  [chains.avalanche.id]: {
    chain: 'AVAX',
    token: 'avax'
  },
  [chains.polygon.id]: {
    chain: 'MATIC',
    token: 'matic'
  },
  // [chains.scroll.id]: {
  //   chain: 'SCROLL',
  //   token: 'scroll_eth'
  // },
  [chains.optimism.id]: {
    chain: 'OPTIMISM',
    token: 'op_eth'
  },
  // [chains.linea.id]: {
  //   chain: 'LINEA',
  //   token: 'linea_eth'
  // },
  // [chains.b3.id]: {
  //   chain: 'B3',
  //   token: 'ethereum'
  // },
  // [chains.duckChainTest.id]: {
  //   chain: 'DuckChainTest',
  //   token: 'ton'
  // },
  // [chains.duckChain.id]: {
  //   chain: 'DuckChain',
  //   token: 'ton'
  // },
  // [chains.ailayer.id]: {
  //   chain: 'AILayer',
  //   token: 'btc'
  // },
  // [chains.bitlayer.id]: {
  //   chain: 'BitLayer',
  //   token: 'btc'
  // },
  // [chains.merlin.id]: {
  //   chain: 'Merlin',
  //   token: 'btc'
  // },
  // [chains.sui.id]: {
  //   chain: 'SUI',
  //   token: 'sui'
  // }
  // [chains.sepolia.id]: {
  //   chain: 'SEPOLIA',
  //   token: 'ethereum'
  // }
}
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export default chains
