import axios from 'axios'
import { mockTonOkxChainID } from 'config/ton'
import { HmacSHA256, enc } from 'crypto-js'
import { TransferHistroyItemType } from 'hooks/useReceiveHistroy'
const api = axios.create({
  baseURL: `${import.meta.env.VITE_AVPIM_API}bot-server/okx/proxy`
})
// const api = axios.create({ baseURL: 'https://www.okx.com' })
const OKX_MAIN_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
export const OKX_TON_MAIN_ADDRESS =
  'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c'
api.interceptors.request.use(
  (config) => {
    const date = new Date()
    // console.log(
    //   'request',
    //   import.meta.env,
    //   config,
    //   date.toISOString() +
    //     'GET' +
    //     config.url +
    //     (config.params
    //       ? '?' + new URLSearchParams(config.params).toString()
    //       : '')
    // )
    config.headers['OK-ACCESS-KEY'] = import.meta.env.VITE_OKX_KEY
    config.headers['OK-ACCESS-SIGN'] = enc.Base64.stringify(
      // The field order of headersParams should be consistent with the order of quoteParams.
      // example : quote  ==>   cryptoJS.HmacSHA256(timestamp + 'GET' + '/api/v5/dex/aggregator/quote?amount=1000000&chainId=1&toTokenAddress=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&fromTokenAddress=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', secretKey)

      HmacSHA256(
        date.toISOString() +
          'GET' +
          config.url +
          (config.params
            ? '?' + new URLSearchParams(config.params).toString()
            : ''),
        import.meta.env.VITE_OKX_SECRET
      )
    )
    config.headers['OK-ACCESS-TIMESTAMP'] = date.toISOString()
    config.headers['OK-ACCESS-PASSPHRASE'] = import.meta.env.VITE_OKX_PASSPHRASE
    config.headers['OK-ACCESS-PROJECT'] = import.meta.env.VITE_OKX_PROJECT

    /*
       else {
        // default token
        config.headers.Authorization = `Bearer ${IS_DEV ? devToken : prodToken}`
      }
      */

    return config
  },
  (error) => {
    if (error?.response?.status === 401) {
      return Promise.reject(error)
    }
  }
)

export const getAggregatorQuoteApi = async (params: {
  chainId: string
  amount: string
  fromTokenAddress: string
  toTokenAddress: string
  dexIds?: string
  priceImpactProtectionPercentage?: string
  feePercent?: string
}): Promise<any> => {
  params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
  params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  const ret = await api.get(`/api/v5/dex/aggregator/quote`, {
    params
  })
  return ret.data
}
export const getAggregatorSwapApi = async (params: {
  chainId: string
  amount: string
  fromTokenAddress: string
  toTokenAddress: string
  slippage: string
  userWalletAddress: string
  refererAddress?: string
  swapReceiverAddress?: string
  feePercent?: string
  gaslimit?: string
  gasLevel?: string
  dexIds?: string
  solTokenAccountAddress?: string
  priceImpactProtectionPercentage?: string
  callDataMemo?: string
  toTokenReferrerAddress?: string
  computeUnitPrice?: string
  computeUnitLimit?: string
}): Promise<any> => {
  if (Number(params.chainId) !== mockTonOkxChainID) {
    params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
    params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  } else {
    params.fromTokenAddress = params.fromTokenAddress || OKX_TON_MAIN_ADDRESS
    params.toTokenAddress = params.toTokenAddress || OKX_TON_MAIN_ADDRESS
  }
  const ret = await api.get(`/api/v5/dex/aggregator/swap`, {
    params
  })
  return ret.data
}

export const getApproveTransactionApi = async (params: {
  chainId: string
  tokenContractAddress: string
  approveAmount: string
}): Promise<any> => {
  params.tokenContractAddress = params.tokenContractAddress || OKX_MAIN_ADDRESS

  const ret = await api.get(`/api/v5/dex/aggregator/approve-transaction`, {
    params
  })
  return ret.data
}

export const getCrossChainQuoteApi = async (params: {
  fromChainId: number
  toChainId: number
  fromTokenAddress: string
  toTokenAddress: string
  amount: string
  sort?: number
  slippage: string
  feePercent?: string
  allowBridge?: number[]
  denyBridge?: number[]
  priceImpactProtectionPercentage?: string
}): Promise<any> => {
  params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
  params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  const ret = await api.get(`/api/v5/dex/cross-chain/quote`, {
    params
  })
  return ret.data
}

export const getCrossChainBuildTxApi = async (params: {
  fromChainId: number
  toChainId: number
  fromTokenAddress: string
  toTokenAddress: string
  amount: string
  sort?: number
  slippage: string
  userWalletAddress: string
  allowBridge?: number[]
  denyBridge?: number[]
  receiveAddress?: string
  feePercent?: string
  referrerAddress?: string
  priceImpactProtectionPercentage?: string
  onlyBridge?: boolean
  memo?: string
}): Promise<any> => {
  params.fromTokenAddress = params.fromTokenAddress || OKX_MAIN_ADDRESS
  params.toTokenAddress = params.toTokenAddress || OKX_MAIN_ADDRESS
  const ret = await api.get(`/api/v5/dex/cross-chain/build-tx`, {
    params
  })
  return ret.data
}

export const getCrossChainStatusApi = async (params: {
  chainId?: number
  hash: string
}): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/cross-chain/status`, {
    params
  })
  return ret.data
}

export const getSupportChainApi = async (): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/aggregator/supported/chain`)
  return ret.data
}

export const getCrossChainSupportedChainApi = async (): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/cross-chain/supported/chain`)
  return ret.data
}

export const getOKXTransactionStatus = async (params: {
  hash: string
  chainId?: string
}): Promise<any> => {
  const ret = await api.get(`/api/v5/dex/cross-chain/status`, { params })
  return ret.data
}

export const getOKXAccountBalance = async (params: {
  address: string
  chains: number[]
  filter?: 0 | 1
}): Promise<any> => {
  const ret = await api.get(
    `/api/v5/wallet/asset/all-token-balances-by-address`,
    { params }
  )
  return ret.data
}

interface OKXResponeType<T> {
  data: T
  msg: string
  code: string
}

export const getTransferHistroy = async (params: {
  address: string
  chains: number | number[]
  begin?: string
  end?: string
  cursor?: string
  limit?: string
}): Promise<
  OKXResponeType<
    { cursor: string; transactionList: TransferHistroyItemType[] }[]
  >
> => {
  const ret = await api.get(
    '/api/v5/wallet/post-transaction/transactions-by-address',
    {
      params,
      paramsSerializer: function (params) {
        return new URLSearchParams(params).toString()
      }
    }
  )
  return ret.data
}

export interface AllTokenBalancesByAddressRes {
  chainIndex: string
  tokenAddress: string
  symbol: string
  balance: string // unit ether
  tokenPrice: string
  tokenType: '1' | '2' // 1 token 2 ordinals
  isRiskToken: boolean
}

// only support chain current: evm and btc
export const getAllTokenBalancesByAddress = async (params: {
  address: string
  chains: number[] | number
  filter?: '0' | '1' //filter risk token
}): Promise<
  OKXResponeType<{ tokenAssets: AllTokenBalancesByAddressRes[] }[]>
> => {
  console.log(params, 'params')
  const ret = await api.get(
    '/api/v5/wallet/asset/all-token-balances-by-address',
    {
      params,
      paramsSerializer: function (params) {
        return new URLSearchParams(params).toString()
      }
    }
  )
  return ret.data
}
